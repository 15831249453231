import React from "react"
import Header from "../../components/header/header"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import ImageGallery from "../../components/gallery/gallery-component"
import { shangrilaphotos } from "../../components/photos/shangri-la-photos"
import "../../../styles/global-styles.scss"

const ShagriLa = () => (
  <Layout>
    <Header />
    <SEO
      title="Dance of the Sugarplum Fairy Style Shoot"
      description="A winter wedding style shoot inspired by the Christmas classic The Nutcracker, designed by YESInspired Events. This elegant affair is set on a crisp white backdrop, accented with pops of rich colors and gold, giving it the classic dance ball feeling. Inspired by ballet and ageless tradition, the winter wedding shoot is a fairy tale come true."
    />
    <h1 className="gallery-photos-heading">
      Dance of the Sugarplum Fairy Style Shoot
    </h1>
    <p className="gallery-photos-text-body">
      A winter wedding style shoot inspired by the Christmas classic The
      Nutcracker, designed by YESInspired Events. This elegant affair is set on
      a crisp white backdrop, accented with pops of rich colors and gold, giving
      it the classic dance ball feeling. Inspired by ballet and ageless
      tradition, the winter wedding shoot is a fairy tale come true.
    </p>
    <ImageGallery photos={shangrilaphotos} />
  </Layout>
)

export default ShagriLa
